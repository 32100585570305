<template>
    <div>
        <button>add</button>
        <Add/>
    </div>
</template>
<script>
import Add from './add'
export default {
    components:{
        Add
    },
    data(){
        return{

        }
    }
}
</script>
<style lang='scss' scoped>
</style>