<template>
    <div class="dialogs">
        <el-dialog
            title="提示"
            :visible.sync="visible"
            :before-close="handleClose"
            :fullscreen="true"
            >
                <div class="content">
                    <el-form :model="ruleForm"   ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="圈子名称" required prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="圈子封面" prop="region">
                            <div class="tip">建议尺寸130*130，文件不超过1MB的jpg、jpeg、png图片</div>
                           <Upload></Upload>
                        </el-form-item>
                        <el-form-item label="圈子简介" required>
                            <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                maxlength="100"
                                show-word-limit
                                rows="6"
                            >   </el-input>
                        </el-form-item>
                        <el-form-item label="圈主简介" prop="delivery">
                            <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                maxlength="100"
                                show-word-limit
                                rows="6"
                            >   </el-input>
                        </el-form-item>
                        <el-form-item label="访问权限" prop="resource">
                            <el-radio-group v-model="ruleForm.resource">
                            <el-radio label="公开圈子"></el-radio>
                            <el-radio label="私密圈子"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="加入限制" prop="resource">
                            <el-radio-group v-model="ruleForm.resource">
                            <el-radio label="所有用户允许加入 - 无需审核"></el-radio>
                            <el-radio label="所有用户允许加入 - 需要审核"></el-radio>
                            <el-radio label="指定用户加入 - 其他用户无法申请加入"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="浏览设置" prop="type">
                            <el-checkbox-group v-model="ruleForm.type">
                            <el-checkbox label="允许未加入用户预览部分主题" name="type"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="成员信息" prop="type">
                            <el-checkbox-group v-model="ruleForm.type">
                            <el-checkbox label="开放成员列表" name="type"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="发布主题权限" prop="resource">
                            <el-radio-group v-model="ruleForm.resource">
                            <el-radio label="所有人"></el-radio>
                            <el-radio label="仅圈主"></el-radio>
                            <el-radio label="圈主+管理员"></el-radio>
                        </el-radio-group>
                        </el-form-item>
                        <el-form-item label="评论回答回复权限" prop="resource">
                            <el-radio-group v-model="ruleForm.resource">
                            <el-radio label="所有人"></el-radio>
                            <el-radio label="仅圈主"></el-radio>
                            <el-radio label="圈主+管理员"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="成员信息" prop="type">
                            <el-checkbox-group v-model="ruleForm.type">
                            <el-checkbox label="允许匿名发布主题" name="type"></el-checkbox>
                            <el-checkbox label="允许匿名评论/回答/回复" name="type"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <div class="footer">
                            <div class="content">
                                <el-button class="btn" @click="resetForm('ruleForm')">取消</el-button>
                                <el-button class="btn" type="primary" @click="submitForm('ruleForm')">确定</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
        </el-dialog>
    </div>
</template>
<script>
import Upload from '@/components/ImageUpload'
export default {
    props: {
        // 是否显示提示
        // visible: {
        //     type: Boolean,
        // }
    },
    components:{Upload},
    data(){
        return{
            visible:true,
            ruleForm: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
        }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>
<style lang='scss' scoped>
.content{
    width: 880px;
    margin: 0 auto;
    /deep/.el-form-item__label{
        float: unset;
    }
    /deep/.el-form-item__content{
        margin-left: 0 !important;
    }
    .footer{
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 10px 16px;
        text-align: right;
        background: transparent;
        border-top: 1px solid #f0f0f0;
        border-radius: 0 0 2px 2px;
        background: #fff;
    }
    .btn{
        width: 64px;
        height: 32px;
    }
}
</style>